import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import "./pages/Pages.css";

document.addEventListener("DOMContentLoaded", (event) => {
  const image = document.getElementById("toggle-image");

  image.addEventListener("click", () => {
    image.classList.toggle("enlarged");
  });
});

function HeroSection() {
  return (
    <div className="hero-container">
      <h1>&lt;FRANK SCHOTANUS/&gt;</h1>
      <p>Student - Leader - Developer</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          Dest="/Aboutme"
        >
          ABOUT ME
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          Dest="/Education"
        >
          EDUCATION
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          Dest="/Projects"
        >
          PROJECTS
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          Dest="/RelevantExperience"
        >
          MY EXPERIENCE
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          Dest="/IrrelevantExperience"
        >
          MY (OTHER)EXPERIENCE
        </Button>
      </div>
      {/* <div className='currently'>
            <p style={{ textDecoration: 'underline', marginBottom: '10px'}}>CURRENTLY:</p>
            <p>STUDENT: Florida State University, BS Computer Science and Computational Biology</p>
            <p>SUMMER INTERN: Wintust Financial, Research and Development</p>
            <p>DIRECTOR OF OUTREACH: 'Noles Everywhere</p>
            <p>RESEARCH ASSISTANt: Stagg Lab FSU, Biology Dept.</p>
            <p>PRESIDENT EMERITUS: ACM at FSU</p>
            </div> */}
      <br />
      <br />
      <br />
      <div className="page-body">
        <p>
          Hi and welcome to my website! I'm Frank Schotanus, a third-year
          student at Florida State University pursuing a double major in
          Computer Science and Computational Biology. I have experience in
          research, industry, and leadership, including 2 internships in
          financial technology with industry leaders. Explore the different
          pages here to learn more about my interests, goals, and experiences.
        </p>
        <div className="home-image">
          <img src="../images/img-9.jpg" alt="Me" />
        </div>
        <div className="caption">
          <p>
            Selfie of me(left) and former Chicago Mayor Lori Lightfoot(right)
          </p>
        </div>
        <br />
        <br />
        <div className="mobile-message">
          <p>
            Hi! You're currently viewing this on a mobile device. Although
            designed to handle a phone, the website is most enjoyed on a
            computer.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
